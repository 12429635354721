body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.banner-bg {
  background-image: -webkit-image-set(
        url("assets/banner2020-1.jpg") 1x,
        url("assets/banner2020-2.jpg") 2x,
        url("assets/banner2020-3.jpg") 3x
  );
  background-image: image-set(
        url("assets/banner2020-1.jpg") 1x,
        url("assets/banner2020-2.jpg") 2x,
        url("assets/banner2020-3.jpg") 3x
  );
}

/*******************************************
********* FarmFLiX.tv Theme Styles *********
********************************************/
