.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-tel-input{
  margin-top:8px;
}

.react-tel-input:focus{
  border:none !important;
  outline: none !important;
}

.react-tel-input .form-control{
  /* background: #eee !important; */
  padding: 14.5px 14px 14.5px 58px !important;
  font-size: 0.95rem !important;
  /* border: none !important; */
  border: 1px solid #a5a5a5 !important;
  border-radius: 5px !important;
}

.react-tel-input .form-control:focus{
  outline: none !important;
  box-shadow: none !important;
}

.react-tel-input .flag-dropdown{
  margin-bottom:1px;
  background-color: #777;
}

.react-tel-input .flag-dropdown:before{
  transition: none !important;
  content: "Country Code and Mobile Number" !important;
  color: #171717 !important;
  /* background: #eee !important; */
  left: 0px !important;
  font-size: 0.9rem !important;
  font-weight: 500;
  padding:0 0 5px 0 !important;
  /* margin-bottom:10px !important; */
  top: -22px !important;
}

.react-tel-input .selected-flag .flag{
  margin-top: -11px !important;
}

.smartbanner{
  z-index: 1000 !important;
}

.smartbanner-top{
  position: inherit !important;
}

.smartbanner-show.smartbanner-margin-top{
  margin-top:0 !important;
}

.braintree-placeholder{
  display:none;
}

.braintree-sheet{
  border:none;
}

.braintree-sheet__header{
  display: none; 
}

.braintree-loaded .braintree-sheet__container{
  background-color:#fff;
}

.braintree-sheet__content--button{
  padding:0;
  min-height: 0;
}

.braintree-sheet--has-error .braintree-sheet{
  border:0;
  border-radius: 0;
}

.braintree-sheet--has-error .braintree-sheet__error{
  background-color:#fff;
  padding: 20px 0;
}

.braintree-dropin.braintree-loaded .braintree-heading{
  display:none;
}

.braintree-show-methods .braintree-toggle{
  display: none;
}

.braintree-methods--active .braintree-method__label{
  font-weight:500;
  color: #171717;
  font-size:0.9rem;
}