
@import "node_modules/videojs-font/scss/icons";

.player-container {
  @import 'node_modules/video.js/src/css/video-js.scss';

  $primary: White;
  $secondary: SpringGreen;
  $white: #ffffff;
  $whiteLight: rgba(255, 255, 255, 0.6);
  $blackDark: rgba(0, 0, 0, 0.6);
  $blackLight: rgba(0, 0, 0, 0.2);
  $chromecast-icon-size: 16px !default;

  display: block;
  width: 100%;
  height: 100%;


  .video {
    width: 100%;
    max-width: 500px;
    height: 300px;
    margin: 3em auto;
  }

  .vjs-tech:focus-visible {
    outline: none;
  }

  .video-js {
    width: 100%;
    min-height: 320px;
  }

  .video-js .vjs-loading-spinner {
    border-color: $blackDark;
  }

  .video-js .vjs-loading-spinner:before {
    border-top-color: $white !important;
  }

  .video-js .vjs-loading-spinner:after {
    border-top-color: $whiteLight !important;
  }

  .video-js .vjs-control-bar {
    display: flex;
  }

  .video-js .vjs-control-bar {
    background-color: $blackDark !important;
  }

  .video-js .vjs-playback-rate .vjs-playback-rate-value {
    font-size: 1em;
    line-height: 3;
  }

  .video-js .vjs-play-progress {
    background-color: $primary;
  }

  .video-js .vjs-play-progress:before {
    color: $primary;
  }

  .video-js .vjs-load-progress div {
    background-color: $whiteLight;
  }

  .video-js .vjs-slider {
    background-color: $secondary;
  }

  .video-js .vjs-big-play-button {
    display: block;
    line-height: 50px;
    height: 50px;
    width: 50px;
    left: 50%;
    top: 50%;
    margin-left: -25px;
    margin-top: -25px;
    border-radius: 100%;
    border: none;
    background-color: $blackLight !important;
  }

  .video-js:hover .vjs-big-play-button,
  .video-js .vjs-big-play-button:focus {
    background-color: $blackDark !important;
  }

  .video-js {
    &.vjs-vtt-thumbnails {
      display: block;
    }

    .vjs-vtt-thumbnail-display {
      position: absolute;
      transition: transform .1s, opacity .2s;
      bottom: 85%;
      pointer-events: none;
      box-shadow: 0 0 7px rgba(0, 0, 0, .6);
    }
  }

  /** Chromecast **/
  $icon-chromecast-default: "./images/chromecast_idle.png" !default;
  $icon-chromecast-casting: "./images/chromecast_connected.png" !default;
  $chromecast-icon-size: 16px !default;

  .vjs-chromecast-button {
    cursor: pointer;

    .vjs-icon-placeholder {
      background: url($icon-chromecast-default) center center no-repeat;
      background-size: contain;
      display: inline-block;
      width: $chromecast-icon-size;
      height: $chromecast-icon-size;
    }

    &.vjs-chromecast-casting-state {
      .vjs-icon-placeholder {
        background-image: url($icon-chromecast-casting);
      }
    }
  }

  /** AirPlay **/
  $icon-airplay-default: "./images/airplay.png" !default;
  $airplay-icon-size: 24px !default;

  .vjs-airplay-button {
    cursor: pointer;

    .vjs-icon-placeholder {
      background: url($icon-airplay-default) center center no-repeat;
      background-size: contain;
      display: inline-block;
      width: $airplay-icon-size;
      height: $airplay-icon-size;
    }
  }
}
